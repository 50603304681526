import React from "react";
import { TbLayersSubtract } from "react-icons/tb";

export const MapStyleButton = ({ mapStyle, setMapStyle, style, size, color }) => {
    return (
        <div className={style}>
            <button
                onClick={() => {
                    if (mapStyle === "mapbox://styles/mapbox/light-v10") {
                        setMapStyle("mapbox://styles/mapbox/satellite-v9");
                    } else {
                        setMapStyle("mapbox://styles/mapbox/light-v10");
                    }
                }}
                className="bg-transparent"
            >
                <TbLayersSubtract size={size} color={color} />
            </button>
        </div>
    );
}