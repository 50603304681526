// This page is just for the map component. It pulls data from MapBox (open source map API) and displays the map on the screen.
// Each polygon is a parking spot. The color of the polygon is determined by the violation type.
// The map is interactive and can be zoomed in and out of.
// The map has default settings that can be changed by passing in props such as zoom and coordinates.

import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import { fetchData, patchData } from "../utility/fetcher";
import { Toaster, toast } from "sonner";
import { IoLocationOutline } from "react-icons/io5";
import { MapStyleButton } from "../utility/mapLayers";
import { useNavigate } from "react-router-dom";
import 'mapbox-gl/dist/mapbox-gl.css';
import "../liveMap/Map.css";

// Mapbox Tokens
mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmhhZ2VydHkzMiIsImEiOiJjbHYyd25vdGEwbXhoMmtwN3hkZGZ6ZmxmIn0.8j4S80-LC8wX7mLHwWrc_g";

// Map Creation
const Signs = ({
    startSpot,
    zoom,
    refresh,
}) => {
    const navigate = useNavigate();
    const currentOrgData = JSON.parse(localStorage.getItem("orgData"));
    if (!currentOrgData) navigate("/");

    const [ogSignData, setOgSignData] = useState([]);
    // const [isDifferent, setIsDifferent] = useState(false);
    const [addCode, setAddCode] = useState(false);
    const [editing, setEditing] = useState(false);
    const [selectedSign, setSelectedSign] = useState(null);
    const [changedTypes, setChangedTypes] = useState([]);
    const [isMapReady, setIsMapReady] = useState(false);
    const [map, setMap] = useState(null);
    const [isStyleLoaded, setIsStyleLoaded] = useState(false);
    const [signs, setSigns] = useState([]);
    const [levelStates, setLevelStates] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [done, setDone] = useState(false);
    const [listContainerHeight, setListContainerHeight] = useState("33%");
    const orgID = currentOrgData.orgID;
    const orgCoordinates = JSON.parse(currentOrgData.orgCoordinates);
    const defaultZoom = currentOrgData.defaultMapZoom;

    const [deleting, setDeleting] = useState(false);
    const [signToDelete, setSignToDelete] = useState(null);
    const [mapStyle, setMapStyle] = useState("mapbox://styles/mapbox/satellite-v9");

    console.log(mapStyle)

    const changeMapStyle = () => {
        if (map) {
            console.log(mapStyle)
            const newStyle = mapStyle === "mapbox://styles/mapbox/satellite-v9" ? "mapbox://styles/mapbox/light-v10" : "mapbox://styles/mapbox/satellite-v9";
            map.setStyle(newStyle);
            setMapStyle(newStyle);
        };
    };

    const getNewData = async () => {
        const newData = await fetchData(`/signs?orgID=${orgID}`);
        setSigns(newData);
        setOgSignData(newData);
        setCurrentTime(new Date());
    };

    useEffect(() => {
        const getInitData = async () => {
            setLoading(true);
            await getNewData();
            setLoading(false);
        };

        getInitData();
    }, []);

    let maxLevel = Math.max(...signs.map(sign => parseInt(sign.level)), 0);
    let minLevel = Math.min(...signs.map(sign => parseInt(sign.level)), 0);

    let levels = { 0: true };
    for (let i = minLevel; i <= maxLevel; i++) {
        levels[i] = false;
    }

    // useEffect(() => {
    //     const checkIfDifferent = () => {
    //         if (ogSignData.length !== signs.length) {
    //             setIsDifferent(true);
    //             return;
    //         }

    //         for (let i = 0; i < signs.length; i++) {
    //             console.log(signs[i], ogSignData[i]);
    //             if (JSON.stringify(signs[i]) !== JSON.stringify(ogSignData[i])) {
    //                 setIsDifferent(true);
    //                 return;
    //             }
    //         }

    //         setIsDifferent(false);
    //     };

    //     checkIfDifferent();
    // }, [signs]);

    const startSpotCoordinates = startSpot || orgCoordinates;
    const mapZoom = zoom || defaultZoom;

    const mapCss = {
        flex: "1",
        bottom: `calc(${listContainerHeight} - 100px)`,
        right: "0px",
        touchAction: "none",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
    };

    useEffect(() => {
        const newMap = new mapboxgl.Map({
            container: "map-container",
            style: mapStyle,
            center: startSpotCoordinates,
            zoom: mapZoom,
            attributionControl: false,
        });

        setMap(newMap);
        setIsMapReady(true);

        newMap.on("load", async () => {
            setIsStyleLoaded(true);
            await addAllSigns(newMap);
        });

        newMap.getCanvas().style.cursor = "default";

        newMap.on("mousemove", (e) => {
            const polygonFeatures = newMap.queryRenderedFeatures(e.point).filter(feature => feature.layer.type === "fill");
            newMap.getCanvas().style.cursor = polygonFeatures.length ? "pointer" : "default";
        });

        return () => newMap.remove();
    }, []);

    useEffect(() => {
        if (map && signs.length > 0) {
            if (!done) {
                addAllSigns(map);
                setDone(true);
            };
        };
    }, [ogSignData, done]);

    const markersMapRef = useRef(new Map());

    const addAllSigns = async (map) => {
        if (!map) {
            console.error("Map instance is undefined.");
            return;
        }

        signs.forEach(sign => {
            try {
                if (!sign.coordinates) {
                    console.warn("Sign is missing coordinates:", sign);
                    return;
                }

                const coordinates = JSON.parse(sign.coordinates);
                if (!Array.isArray(coordinates) || coordinates.length !== 2) {
                    console.warn("Invalid coordinates format:", coordinates);
                    return;
                }

                const marker = new mapboxgl.Marker({ color: "#ffe374" })
                    .setLngLat(coordinates)
                    .setDraggable(true)
                    .addTo(map);

                const el = document.createElement('div');
                el.className = 'font-semibold mt-1 text-center absolute left-1/2 transform -translate-x-1/2 bg-white px-2 py-1 rounded-md shadow-md';
                el.style.whiteSpace = 'nowrap';
                el.textContent = sign.locatorName ? sign.locatorName : sign.locationCode;
                marker.getElement().appendChild(el);

                marker.on('dragend', () => {
                    const newCoords = marker.getLngLat().toArray();
                    changeLocationCoords(sign.locationCode, newCoords);
                });

                marker.getElement()?.addEventListener('click', () => setSelectedSign(sign));

                // Store the marker reference in the ref's map
                markersMapRef.current.set(sign.locationCode, marker);

            } catch (error) {
                console.error("Error adding sign to map:", error);
            }
        });
    };

    const [newSign, setNewSign] = useState({
        locatorName: "",
        locationCode: "",
        zoom: 0,
        coordinates: [],
        orgID: orgID,
    });

    const flyToSpot = (spot) => {
        map.flyTo({
            center: JSON.parse(spot.coordinates),
            zoom: spot.zoom,
            essential: true,
        });
    };

    useEffect(() => {
        if (editing && map) {
            const handleClick = (e) => {
                const coordinates = [e.lngLat.lng, e.lngLat.lat];
                const newSign = {
                    locatorName: "",
                    locationCode: "",
                    zoom: Math.round(map.getZoom() * 10) / 10,
                    coordinates: JSON.stringify(coordinates),
                };

                setNewSign(newSign);
                setTimeout(() => {
                    const lastInput = document.querySelector("tbody tr:last-child input[type='text']");
                    if (lastInput) {
                        lastInput.focus();
                    }
                }, 0);

                setEditing(false);
                setAddCode(true);
            };

            map.on("click", handleClick);

            return () => {
                map.off("click", handleClick);
            };
        }
    }, [editing, map]);

    const changeLocationCoords = (locationCode, newCoords) => {
        setSigns(prevSigns => {
            const newSigns = [...prevSigns];
            const signIndex = newSigns.findIndex(sign => sign.locationCode === locationCode);

            if (signIndex !== -1) {
                newSigns[signIndex].coordinates = JSON.stringify(newCoords);
            } else {
                console.warn("Location code not found:", locationCode);
            };

            return newSigns;
        });
    };

    const saveNewSign = () => {
        try {
            if (!newSign.coordinates) {
                console.warn("Sign is missing coordinates:", newSign);
                return;
            }

            const coordinates = JSON.parse(newSign.coordinates);
            if (!Array.isArray(coordinates) || coordinates.length !== 2) {
                console.warn("Invalid coordinates format:", coordinates);
                return;
            }

            const marker = new mapboxgl.Marker({ color: "#ffe374" })
                .setLngLat(coordinates)
                .setDraggable(true)
                .addTo(map);

            const el = document.createElement('div');
            el.className = 'font-semibold mt-1 text-center absolute left-1/2 transform -translate-x-1/2 bg-white px-2 py-1 rounded-md shadow-md';
            el.style.whiteSpace = 'nowrap';
            el.textContent = newSign.locatorName ? newSign.locatorName : newSign.locationCode;
            marker.getElement().appendChild(el);

            marker.on('dragend', () => {
                const newCoords = marker.getLngLat().toArray();
                changeLocationCoords(newSign.locationCode, newCoords);
            });

            marker.getElement()?.addEventListener('click', () => setSelectedSign(newSign));

            // Store the marker reference in the ref's map
            markersMapRef.current.set(newSign.locationCode, marker);

        } catch (error) {
            console.error("Error adding sign to map:", error);
        };

        setSigns(prevSigns => [...prevSigns, newSign]);

        setNewSign({
            locatorName: "",
            locationCode: "",
            zoom: 0,
            coordinates: [],
        });

        setAddCode(false);
    };

    const deleteSign = (locationCode) => {
        // Remove marker from map
        setSigns(prevSigns => prevSigns.filter(sign => sign.locationCode !== locationCode));
        const marker = markersMapRef.current.get(locationCode);
        if (marker) {
            marker.remove(); // Remove from the map
            markersMapRef.current.delete(locationCode); // Remove from reference
            setSigns(prevSigns => prevSigns.filter(sign => sign.locationCode !== locationCode)); // Remove from state
        } else {
            console.warn("Marker not found for locationCode:", locationCode);
        }
    };

    const saveToDatabase = async () => {
        const hasEmptyFields = signs.some(sign =>
            !sign.locatorName || !sign.locationCode || !sign.zoom || !sign.coordinates
        );

        if (hasEmptyFields) {
            toast.error("Please fill in all fields before saving.");
            return;
        };

        try {
            console.log(signs)
            const response = await patchData(`/signs`, signs);
            console.log(response)
            if (response.error) {
                console.error("Error saving signs:", response.error);
                toast.error("Failed to save signs.");
                return;
            } else {
                console.log("Signs saved successfully!");
                toast.success("Signs saved successfully!");
                setOgSignData(signs);
                // setIsDifferent(false);
            }
        } catch (error) {
            console.error("Error saving signs:", error);
            toast.error("Failed to save signs.");
        };
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                setAddCode(false);
                setEditing(false);
                setNewSign({
                    locatorName: "",
                    locationCode: "",
                    zoom: 0,
                    coordinates: [],
                });
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [addCode, editing]);

    const editTitle = (title, locationCode, index) => {
        const newSigns = [...signs];
        newSigns[index].locatorName = title;
        setSigns(newSigns);

        const marker = markersMapRef.current.get(locationCode);
        if (marker) {
            const el = marker.getElement().querySelector("div");
            if (el) el.textContent = title;
        };
    };

    return (
        <div className="w-full h-full flex flex-col">
            <div id="map-container" style={mapCss}>
                {/* Remove styling for original popup */}
                <style>
                    {`
                    .mapboxgl-popup {
                        background: none !important;
                        box-shadow: none !important;
                        border: none !important;
                    }

                    .mapboxgl-popup-content {
                        background: none !important;
                        box-shadow: none !important;
                        border: none !important;
                    }
                `}
                </style>

                {/* LOADING */}
                {loading && (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <div style={{
                            width: '80px',
                            height: '80px',
                            border: '8px solid #f3f3f3',
                            borderTop: '8px solid #ffe374',
                            borderRadius: '50%',
                            animation: 'App-logo-spin 1s linear infinite'
                        }}></div>
                    </div>
                )}
            </div >

            {/* MAP STYLE BUTTON */}
            <MapStyleButton
                mapStyle={mapStyle}
                size={40}
                setMapStyle={changeMapStyle}
                color={mapStyle === "mapbox://styles/mapbox/satellite-v9" ? "#fff" : "#323232"}
                style="absolute p-1 bg-white/50 backdrop-blur-md border rounded-xl top-5 right-5 z-[50] flex justify-center items-center"
            />

            {/* INSTRUCTIONS */}
            {editing && (
                <div className="absolute top-5 right-5 p-4 bg-white/20 backdrop-blur-md border rounded-xl bg-opacity-90 flex flex-col justify-center items-center z-[50]">
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-2xl font-semibold text-spotGray">Add a Sign</h1>
                        <p className="text-lg text-spotGray text-center">Click a location on the map <br /> to add a new sign.</p>
                    </div>
                </div>
            )}

            {addCode && (
                <div className="fixed inset-0 p-4 bg-white/20 backdrop-blur-md border rounded-xl flex flex-col justify-center items-center z-[50] select-none">
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-2xl font-semibold text-spotGray">Add a Code</h1>
                        <p className="text-lg text-spotGray text-center">Enter the code below:</p>
                        <input
                            type="text"
                            className="mt-2 p-2 border rounded-lg outline-none"
                            value={newSign.locationCode}
                            maxLength={4}
                            placeholder="Enter code"
                            onChange={(e) => setNewSign({
                                ...newSign,
                                locationCode: e.target.value.toUpperCase()
                            })}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" && newSign.locationCode.length === 4) {
                                    saveNewSign();
                                }
                            }}
                        />
                        <button
                            className="mt-4 bg-spotYellow font-bold hover:shadow-lg text-spotGray transition-all duration-100 px-4 py-2 rounded-xl"
                            onClick={saveNewSign}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            )}

            {deleting && (
                <div className="fixed inset-0 p-4 bg-white/20 backdrop-blur-md border rounded-xl flex flex-col justify-center items-center z-[50] select-none">
                    <div className="flex flex-col justify-center items-center">
                        <h1 className="text-2xl font-semibold text-spotGray">Delete Sign</h1>
                        <p className="text-lg text-spotGray text-center">Are you sure you want to delete this sign?</p>
                        <div className="flex flex-row justify-center items-center gap-4 mt-4">
                            <button
                                className="bg-gray-400 hover:bg-gray-500 font-bold hover:shadow-lg text-white transition-all duration-100 px-4 py-2 rounded-xl"
                                onClick={() => {
                                    setDeleting(false)
                                    setSignToDelete(null)
                                }}
                            >
                                No
                            </button>
                            <button
                                className="bg-spotYellow hover:bg-red-400 hover:text-white font-bold hover:shadow-lg text-spotGray transition-all duration-100 px-4 py-2 rounded-xl"
                                onClick={() => {
                                    deleteSign(signToDelete.locationCode);
                                    setSignToDelete(null);
                                    setDeleting(false);
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* TABLE */}
            <div className="list-container flex flex-col absolute bottom-0 w-full bg-white resize-y shadow-xl border overflow-auto z-[40]"
                style={{ height: "33%", minHeight: '300px' }}
            >
                {/* HANDLE */}
                <div className="h-4 bg-white cursor-row-resize relative flex flex-col justify-center items-center"
                    onMouseDown={(e) => {
                        e.preventDefault();
                        const onMouseMove = (e) => {
                            const newHeight = ((window.innerHeight - e.clientY) / window.innerHeight) * 100;
                            document.querySelector(".list-container").style.height = `${newHeight}%`;
                            setListContainerHeight(`${newHeight}%`);
                        };
                        document.addEventListener("mousemove", onMouseMove);
                        document.addEventListener("mouseup", () => {
                            document.removeEventListener("mousemove", onMouseMove);
                        });
                    }}
                >
                    <div className="absolute flex flex-col justify-between items-center left-1/2 -translate-x-1/2 w-fit h-[5px] z-50">
                        <div className="h-[2px] w-6 bg-gray-400 rounded-full" />
                        <div className="h-[2px] w-6 bg-gray-400 rounded-full" />
                    </div>
                </div>

                {/* DATA */}
                <div className="overflow-auto w-full h-full pl-24">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Code</th>
                                <th className="py-2 px-4 border-b">Title</th>
                                <th className="py-2 px-4 border-b">Zoom</th>
                                {/* <th className="py-2 px-4 border-b">Level</th> */}
                                <th className="py-2 px-4 border-b">Locate</th>
                                <th className="py-2 px-4 border-b">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {signs.map((sign, index) => (
                                <tr key={index}>
                                    <td className="py-2 px-4 border-b">
                                        <input
                                            type="text"
                                            value={sign.locationCode}
                                            maxLength={4}
                                            disabled={true}
                                            onChange={(e) => {
                                                const newSigns = [...signs];
                                                newSigns[index].locationCode = e.target.value;
                                                setSigns(newSigns);
                                            }}
                                            className="w-full border p-2 text-gray-500 rounded-lg border-white  outline-spotYellow text-center select-none"
                                        />
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <input
                                            type="text"
                                            value={sign.locatorName}
                                            onChange={(e) => editTitle(e.target.value, sign.locationCode, index)}
                                            className="w-full border p-2 rounded-lg border-white hover:border-gray-100  outline-spotYellow text-center"
                                        />
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <input
                                            type="number"
                                            step={0.1}
                                            value={sign.zoom}
                                            min={16}
                                            max={22}
                                            onChange={(e) => {
                                                const newSigns = [...signs];
                                                newSigns[index].zoom = e.target.value.toString();
                                                setSigns(newSigns);
                                            }}
                                            className="w-full border p-2 rounded-lg border-white hover:border-gray-100  outline-spotYellow text-center"
                                        />
                                    </td>
                                    {/* <td className="py-2 px-4 border-b">
                                        <input
                                            type="number"
                                            value={sign.level}
                                            onChange={(e) => {
                                                const newSigns = [...signs];
                                                newSigns[index].level = e.target.value.toString();
                                                setSigns(newSigns);
                                            }}
                                            className="w-full border p-2 rounded-lg border-white hover:border-gray-100  outline-spotYellow"
                                        />
                                    </td> */}
                                    <td className="py-2 px-4 border-b">
                                        <IoLocationOutline size={40} className="text-2xl ml-4 text-spotGray cursor-pointer hover:text-spotYellow" onClick={() => flyToSpot(sign)} />
                                    </td>
                                    <td className="py-2 px-4 border-b">
                                        <button
                                            className="bg-gray-400 ml-6 hover:bg-red-500 font-bold hover:shadow-lg transition-all duration-100 text-white px-4 py-2 rounded-xl"
                                            onClick={() => {
                                                setDeleting(true);
                                                setSignToDelete(sign);
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}

                            {/* Add new sign */}
                            <tr className="py-2 px-4 border-b">
                                <td className="py-2 px-4 border-b">
                                    <button
                                        className="bg-gray-400 hover:bg-green-400 font-bold hover:shadow-lg transition-all duration-100 text-white px-4 py-2 rounded-xl"
                                        onClick={() => {
                                            setEditing(true);
                                        }}
                                    >
                                        Add New Sign
                                    </button>
                                </td>
                                <td className="py-2 px-4 border-b"></td>
                                <td className="py-2 px-4 border-b"></td>
                                <td className="py-2 px-4 border-b"></td>
                                <td className="py-2 px-4 flex justify-center">
                                    <button
                                        className="bg-spotYellow font-bold hover:shadow-lg text-spotGray transition-all duration-100 px-4 py-2 rounded-xl"
                                        onClick={saveToDatabase}
                                    >
                                        Save
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Toaster richColors />
        </div>
    );
};

export default Signs;