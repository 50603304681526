import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BsFillGearFill } from 'react-icons/bs';
import { BiSolidDashboard } from "react-icons/bi";
import { FaMap, FaParking, FaHistory } from "react-icons/fa";
import { MdAnalytics, MdAccountCircle } from "react-icons/md";
import { MdHelp } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import { FaExclamationTriangle } from "react-icons/fa";
import { HiUserGroup } from "react-icons/hi";
import { RiMegaphoneFill } from "react-icons/ri";
import { PiReceiptBold } from "react-icons/pi";
import { BsPassFill } from "react-icons/bs";
import { IoTrailSign } from "react-icons/io5";
import Cookies from 'js-cookie';

const Sidebar = () => {
    const wide = window.innerWidth > 768;
    const location = useLocation();
    const currentPath = location.pathname;
    const sideNavRef = useRef(null);
    const hamburgerButtonRef = useRef(null);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        // Add event listener to the document object
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const MobileListItem = ({ url, icon, text }) => {
        const isActive = currentPath === url || (url !== "/" && currentPath.includes(url));

        return (
            <Link
                to={url}
                onClick={() => setSidebarOpen(false)}
                className={`flex items-center justify-start pl-4 w-full h-16 ${isActive ? "bg-gray-700 text-white" : "hover:bg-gray-600 hover:text-white"} rounded-md`}
            >
                {icon}
                <span className={`ml-2 ${isActive ? "bg-gray-700 text-white" : "hover:bg-gray-600 hover:text-white"} text-s font-bold`}>{text}</span>
            </Link>
        );
    }

    const handleClickOutside = (event) => {
        if (
            sideNavRef.current &&
            !sideNavRef.current.contains(event.target) &&
            hamburgerButtonRef.current &&
            !hamburgerButtonRef.current.contains(event.target) // Check if the event target is outside the hamburger button
        ) {
            setSidebarOpen(false);
        }
    };

    const handleHamburgerClick = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const SidebarIcon = ({ url, icon, text }) => {
        const isActive = currentPath === url || (url !== "/" && currentPath.includes(url));
        const height = text === "Analytics" ? "hover:h-[171px]" : "hover:h-[241px]";

        const colors = {
            "Dashboard": "bg-gradient-to-br from-yellow-200 to-spotYellow",
            "Map": "bg-gradient-to-br from-green-300 to-green-400",
            "Analytics": "bg-gradient-to-br from-sky-300 to-sky-400",
            "Settings": "bg-gradient-to-br from-gray-500 to-gray-600",
            "Announcements": "bg-gradient-to-br from-blue-200 to-blue-300",
            "Help": "bg-gradient-to-br from-orange-200 to-orange-300",
            "Log Out": "bg-gradient-to-br from-red-300 to-red-400"
        }

        const hoverColors = {
            "Dashboard": "hover:bg-gradient-to-br from-yellow-100 to-yellow-300",
            "Map": "hover:bg-gradient-to-br from-green-100 to-green-300",
            "Analytics": "hover:bg-gradient-to-br from-sky-200 to-sky-300",
            "Settings": "hover:bg-gradient-to-br from-gray-500 to-gray-600",
            "Announcements": "hover:bg-gradient-to-br from-blue-100 to-blue-200",
            "Help": "hover:bg-gradient-to-br from-orange-200 to-orange-300",
            "Log Out": "hover:bg-gradient-to-br from-red-200 to-red-300"
        }

        const hoverAnimation = {
            "Dashboard": "group-hover:rotate-90",
            "Map": "group-hover:scale-125",
            "Analytics": "group-hover:scale-[125%]",
            "Announcements": "group-hover:-rotate-[25deg] group-hover:scale-[115%]",
            "Settings": "group-hover:rotate-180",
            "Help": "group-hover:rotate-[359deg]",
            "Log Out": "group-hover:scale-[125%]"
        }

        const folder = {
            "Analytics": {
                "History": <FaHistory size={20} />,
                "Current Violations": <FaExclamationTriangle size={20} />,
                "Citations": <PiReceiptBold size={20} />,
            },
            "Settings": {
                "My Account": <MdAccountCircle size={20} />,
                "Users": <HiUserGroup size={20} />,
                "Lots": <FaParking size={20} />,
                "Permits": <BsPassFill size={20} />,
                "Signs": <IoTrailSign size={20} />,
            }
        }

        const handleLogOut = () => {
            sessionStorage.clear();
            localStorage.clear();
            Cookies.remove("token");
            window.location.href = "/login";
        }

        if (text === "Analytics" || text === "Settings") {
            return (
                <div className={`relative items-center justify-center rounded-3xl w-16 h-16 mt-2 mb-2 m-auto ${isActive ? `${height} text-gray-200` : `${hoverColors[text]} hover:rounded-[10px] ${height}`}  transition-all duration-200 ease-in-out group ${isActive ? `${colors[text]} text-gray-700 rounded-[10px]` : `bg-gray-300 hover:text-white ${text === "Settings" ? "text-gray-500 hover:text-gray-300" : "text-gray-500"}`}`}>
                    {/* Icon */}
                    <div className={`${isActive ? `flex justify-center align-middle mt-[15px] drop-shadow-lg text-white` : `flex justify-center group-hover:drop-shadow-lg ${hoverAnimation[text]} transition-all duration-500 ease-in-out align-middle mt-[15px]`}`}>
                        {icon}
                    </div>
                    <div className={`group-hover:flex group-hover:flex-col group-hover:justify-center group-hover:align-middle cursor-pointer hidden w-auto p-2 group-hover:ml-[-2px] min-w-max text-s ${text === "Settings" ? "text-gray-300" : "text-gray-500"} font-bold transition-all duration-100 origin-left group-hover:opacity-100  ${isActive ? null : "opacity-0"}`}>
                        {Object.entries(folder[text]).map(([key, value], index) => (
                            // Sub Icon
                            <Link
                                to={`/${text.toLowerCase()}/` + key.toLowerCase().replace(/\s/g, '-')}
                                key={index}
                                aria-label={key}
                                className={`py-2 px-4 ${text.toLowerCase() === "analytics" ? "hover:text-sky-200 hover:bg-gray-600" : "hover:bg-gray-300 hover:text-gray-600"} rounded-md transition-all text-white drop-shadow-lg duration-100 ease-in-out group/sub`}
                            >
                                {value}

                                {/* Tooltip */}
                                <div
                                    className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md bg-gray-700 text-xs text-white font-bold transition-all duration-100 scale-0 origin-left group-hover/sub:scale-100"
                                    style={{ top: `${index - 7}px` }}
                                >
                                    {key}
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            );
        } else if (text === "Log Out") {
            return (
                <div
                    className={`relative flex items-center hover:text-white justify-center cursor-pointer rounded-3xl w-16 h-16 mt-2 mb-2 mx-auto ${isActive ? null : `${hoverColors[text]} hover:rounded-[10px]`}  transition-all duration-100 ease-in-out group ${isActive ? `${colors[text]} text-gray-700 rounded-[10px]` : "bg-gray-300 text-gray-500"}`}
                    onClick={handleLogOut}
                    aria-label='Log Out'
                >
                    <div className={`${isActive ? "drop-shadow-lg" : `transition-all duration-100 ease-in-out`} ${hoverAnimation[text]} transition-all duration-500 ease-in-out`}>
                        {icon}
                    </div>
                    <span className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md bg-gray-700 text-s text-white font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
                        {text}
                    </span>
                </div>
            );
        } else {
            return (
                <Link
                    to={url}
                    aria-label={text}
                    className={`relative flex items-center justify-center cursor-pointer rounded-3xl w-16 h-16 mt-2 mb-2 mx-auto ${isActive ? null : `${hoverColors[text]} hover:rounded-[10px]`}  transition-all duration-200 ease-in-out group ${isActive ? `${colors[text]} text-white rounded-[10px]` : "bg-gray-300 text-gray-500"}`}
                >
                    <div className={`${isActive ? "drop-shadow-lg" : `transition-all duration-100 ease-in-out`} group-hover:text-white ${hoverAnimation[text]} transition-all duration-500 ease-in-out`}>
                        {icon}
                    </div>
                    <span className="absolute w-auto p-2 m-2 min-w-max left-20 rounded-md bg-gray-700 text-s text-white font-bold transition-all duration-100 scale-0 origin-left group-hover:scale-100">
                        {text}
                    </span>
                </Link>
            );
        };
    };

    if (wide) {
        return (
            <div className="flex fixed top-0 left-0 h-screen w-20 m-0 flex-col min-w-20 bg-transparent text-white z-[100] backdrop-blur-md">
                <div className='fixed left-2 flex flex-col bg-transparent'>
                    <SidebarIcon url={"/"} icon={<BiSolidDashboard size="32" />} text='Dashboard' />
                    <SidebarIcon url={"/map"} icon={<FaMap size="32" />} text='Map' />
                    <SidebarIcon url={"/analytics"} icon={<MdAnalytics size="35" />} text='Analytics' />
                    <SidebarIcon url={"/announcements"} icon={<RiMegaphoneFill size="32" className=' -rotate-12' />} text="Announcements" />
                    <SidebarIcon icon={<BsFillGearFill size="32" />} text='Settings' url={"/settings"} />
                    <hr className='flex justify-center align-middle ml-2 w-12 border-1 border-gray-400 mt-2 mb-2'></hr>
                    <SidebarIcon url={"/help"} icon={<MdHelp size="32" />} text="Help" />
                </div>
                <div className='fixed flex align-bottom justify-end bottom-0 left-2'>
                    <SidebarIcon url={"/login"} icon={<IoLogOut size="32" />} text="Log Out" />
                </div>
            </div>
        );
    } else {
        return (
            <div className="fixed md:hidden flex flex-row justify-end top-0 w-screen h-16 bg-spotGray/20 z-50 backdrop-blur-md text-white shadow-lg font-rubik" ref={sideNavRef}>
                <button className="group fixed w-8 h-8 top-4 left-4 z-50" onClick={handleHamburgerClick} ref={hamburgerButtonRef}>
                    <div className="grid justify-items-center gap-1.5" ref={sideNavRef}>
                        <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black rotate-45 translate-y-2.5" : "bg-spotGray"} transition duration-700`}></span>
                        <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black scale-x-0" : "bg-spotGray"} duration-[0.5s] transition`}></span>
                        <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black -rotate-45 -translate-y-2.5" : "bg-spotGray"} transition duration-700`}></span>
                    </div>
                </button>
                <div className={`fixed flex flex-col gap-4 top-0 left-0 h-screen w-[62vw] bg-white text-spotGray shadow-lg -translate-x-[101%] transition-transform duration-500 z-30 ${sidebarOpen ? "translate-x-[0%]" : null}`} ref={sideNavRef}>
                    <div className='mt-20 flex flex-col justify-start items-start'>
                        <MobileListItem url={"/"} icon={<BiSolidDashboard size="32" />} text='Dashboard' />
                        <MobileListItem url={"/map"} icon={<FaMap size="32" />} text='Map' />
                        <MobileListItem url={"/analytics"} icon={<MdAnalytics size="35" />} text='Analytics' />
                        <MobileListItem url={"/announcements"} icon={<RiMegaphoneFill size="32" className=' -rotate-12' />} text="Announcements" />
                        <MobileListItem icon={<BsFillGearFill size="32" />} text='Settings' url={"/settings"} />
                        <hr className='flex justify-center align-middle ml-2 w-12 border-1 border-gray-400 mt-2 mb-2'></hr>
                        <MobileListItem url={"/help"} icon={<MdHelp size="32" />} text="Help" />
                    </div>
                    <div className='fixed flex align-bottom justify-end bottom-0 left-2'>
                        <MobileListItem url={"/login"} icon={<IoLogOut size="32" />} text="Log Out" />
                    </div>
                </div>
            </div>
        );
    };
}

export default Sidebar;